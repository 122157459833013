// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import './plugins/google-map';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import { Fancybox } from '@fancyapps/ui';
// import ScrollOut from 'scroll-out';

/* global Swiper */
/* global ajax_object */

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   * a[href$="jpg"], a[href$="png"], a[href$="gif"], a[href$="webp"]
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"], a[href$="webp"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');

  Fancybox.bind('[data-fancybox], .fancybox, a[rel*="album"]');

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Override bootstrap submenu toggle
   */
  $('.navbar .menu-item-has-children').hover(function () {
    if ($(window).width() > 991) {
      $(this).toggleClass('show');
      alignMenuDropdown($(this).children('.dropdown-menu'));
    }
  });

  // Prevent dropdown menu opening out of window
  function alignMenuDropdown($dropdown) {
    if ($dropdown === undefined || !$dropdown.length) return false;
    $dropdown.toggleClass(
      'dropdown-menu-left',
      $dropdown.offset().left + $dropdown.width() > $(window).width()
    );
  }

  // Dropdown menu mobile toggle
  $('.js-dropdown-toggle').bind('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    if ($(window).width() <= 991) {
      $(this).closest('.dropdown').toggleClass('show');
    }
  });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    // jQuery code goes here
  });

  // Apply scrolling classes on autoscroll
  $(window).trigger('scroll');

  // Init Swiper
  if ($('.js-home-slider').length && typeof Swiper !== undefined) {
    new Swiper('.js-home-slider', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 3000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      loop: true,
      speed: 1500,
      spaceBetween: 0,
      slidesPerView: 1,
    });
  }

  // Init Flexible Slider
  let $sliders = $('.js-slider');
  if ($sliders.length && typeof Swiper !== undefined) {
    $sliders.each(function (i, el) {
      let $this = $(el);
      let $section = $this.closest('.s-slider');
      let defaults = {
        loop: true,
        speed: 1500,
        spaceBetween: 0,
        slidesPerView: 2,
      };
      let options = $this.data('options') ?? {};

      if (options.autoplay) {
        options.autoplay = {
          pauseOnMouseEnter: true,
        };
      }

      if (options.navigation) {
        options.navigation = {
          prevEl: $section.find('.swiper-button-prev').get(0),
          nextEl: $section.find('.swiper-button-next').get(0),
        };
      }

      if (options.pagination) {
        options.pagination = {
          el: $section.find('.swiper-pagination').get(0),
          dynamicBullets: true,
          clickable: true,
        };
      }

      new Swiper(el, { ...defaults, ...options });
    });
  }

  // Show hero video slowly
  $('.js-hero-video').on('play', function () {
    $(this).fadeTo('slow', 1);
  });

  resizeVideo();
  calculatePostHeight();
  blogLoadMore();
}); // END of document ready

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  // Optimize home slider (mobile)
  setTimeout(() => {
    let $sliderPreview = $('#home-slider-preview');
    let $homeSlider = $('#home-slider');
    if (
      $(window).width() <= 768 &&
      $sliderPreview.length &&
      $homeSlider.length
    ) {
      $sliderPreview.hide();
      $homeSlider.show();
    }
  }, 1200);

  calculatePostHeight();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  calculatePostHeight();
});

/**
 * Scripts which runs on scrolling
 */
let lastScrollTop = 0;
$(window).on('scroll', function () {
  // jQuery code goes here
  let st = $(this).scrollTop();
  $(document.body)
    .toggleClass('scrolling-up', st < lastScrollTop)
    .toggleClass('scrolling-down', st > lastScrollTop)
    .toggleClass('scrolling', st !== 0);
  if (st === 0) $('body').removeClass('scrolling-up scrolling-down');
  lastScrollTop = st;
});

// Calculate
function calculatePostHeight() {
  let $postsList = $(document).find('.js-posts-list');

  if ($postsList.length) {
    let offsets = [];
    $postsList.find('> .post-item').each((i, el) => {
      let wrapperHeight = $(el).height();
      let postHeight = $(el).find('article').height();
      let gap = i - 2;
      let offset = Math.round(wrapperHeight - postHeight) * -1;

      offsets.push(offset);

      if (gap >= 0 && offsets[gap] !== undefined) {
        $(el).css('--post-offset', offsets[gap] + 'px');
      }
    });
  }
}

// Blog load more
function blogLoadMore() {
  $(document).on('click', '.js-load-posts:not(.is-loading)', function (e) {
    e.preventDefault();

    let $this = $(this),
      $blogMain = $this.closest('.main-blog'),
      $blogContainer = $blogMain.find('.js-posts-list'),
      $currPage = $this.data('page');

    $blogContainer.addClass('searching');

    $this.addClass('is-loading');

    // AJAX Search
    $.post(ajax_object.ajax_url, {
      action: 'load_articles',
      category: $this.data('category'),
      number: $this.data('number'),
      page: $currPage,
    }).done(function (response) {
      if (response.html) {
        $blogContainer.append(response.html);
      }
      $blogContainer.removeClass('searching');
      $this
        .removeClass('is-loading')
        .toggleClass('d-none', $this.data('number') >= response.count);
      $this.data('page', $currPage++);
      calculatePostHeight();
    });
  });
}
